import L from "leaflet";

// import "leaflet/dist/leaflet.css";

// Import the Leaflet MapTiler Plugin
// import "@maptiler/leaflet-maptilersdk";

const mapZoomLevel = 2;
export let latSouthwest;
export let lngSouthWest;
export let latNorthEast;
export let lngNorthEast;
export let map;

export const loadmap = function (lat, lng) {
  const imageUrl =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwwJRZ18vXhxzTxYzapLu819tvm0TQDHFWDw&s";
  const errorOverlayUrl =
    "https://cdn-icons-png.flaticon.com/512/110/110686.png";
  const altText =
    "Image of Newark, N.J. in 1922. Source: The University of Texas at Austin, UT Libraries Map Collection.";

  map = L.map("map", {
    center: [0, 0], // Set the initial center of the map
    zoom: 2, // Set the initial zoom level
    zoomControl: false, // Disable the zoom controls (+/- buttons)
    scrollWheelZoom: false, // Disable zooming with scroll wheel
    doubleClickZoom: false, // Disable zooming on double click
    dragging: false, // Disable dragging (optional)
    touchZoom: false, // Disable touch-based zoom (optional)
    boxZoom: false, // Disable zooming with box selection (optional)
    keyboard: false, // Disable zooming with keyboard (optional)
  });
  // .setView([lat, lng]);
  const bounds = map.getBounds();

  latSouthwest = bounds.getSouthWest().lat;
  console.log(latSouthwest);

  lngSouthWest = bounds.getSouthWest().lng;
  latNorthEast = bounds.getNorthEast().lat;
  lngNorthEast = bounds.getNorthEast().lng;

  // Create the image overlay using the map bounds
  const imageOverlay = L.imageOverlay(imageUrl, [
    [latSouthwest, lngSouthWest], // Southwest corner
    [latNorthEast, lngNorthEast], // Northeast corner
  ]).addTo(map);

  // map.fitBounds(latLngBounds);
  // Listen for map resize events to update the image overlay bounds
  map.on("resize", function () {
    const newBounds = map.getBounds();

    latSouthwest = newBounds.getSouthWest().lat;
    console.log(latSouthwest);

    lngSouthWest = newBounds.getSouthWest().lng;
    latNorthEast = newBounds.getNorthEast().lat;
    lngNorthEast = newBounds.getNorthEast().lng;
    imageOverlay.setBounds([
      [latSouthwest, lngSouthWest],
      [latNorthEast, lngNorthEast],
    ]);
  });
  // L.rectangle(latLngBounds).addTo(map);
};

import "core-js/stable";
import "regenerator-runtime/runtime";
import loadTractorList from "./loadTractorList.js";
import {
  loadmap,
  map,
  lngSouthWest,
  latNorthEast,
  lngNorthEast,
} from "./loadMap.js";
// import loadPopUp from "./loadPopup.js";
// import activitateTractor from "./activitateTractor.js";
import renderMarker from "./renderMarker.js";
// import setMaxBound from "./setMaxBound.js";
import renderTable from "./renderTable.js";

export const latLng = [];
export const markers = [];
export const timeStamps = [];
const batteryLevel = [];

export const tractorList = document.querySelector(".tractor-list");
export const tractorListType = document.querySelector(".tractor-list-type");
export const tbody = document.querySelector("tbody");
export const thead = document.querySelector("thead");
export const tractorTable = document.querySelector(".tractor-table");
const logoBox = document.querySelector(".logo-box");
const sidebar = document.querySelector(".sidebar");
const app = document.querySelector(".app");
const main = document.querySelector(".main");
const mapComp = document.querySelector(".map-component");
const mapheading = document.querySelector(".map-heading");
const logoBoxHeight = logoBox.getBoundingClientRect().height;
const logoBoxWidth = logoBox.getBoundingClientRect().width;
const mapFE = document.querySelector("#map");
const welHeight = document
  .querySelector(".welcome-msg")
  .getBoundingClientRect().height;

sidebar.style.height = `${window.innerHeight - logoBoxHeight}px`;
app.style.height = `${window.innerHeight - logoBoxHeight}px`;
sidebar.style.width = `${logoBoxWidth}px`;
const sidebarWidth = sidebar.style.width;
console.log(sidebarWidth);
sidebar.style.zIndex = `10`;
app.style.display = "grid";
app.style.gridTemplateColumns = `${sidebarWidth} auto`;
app.style.height = `${
  window.innerHeight - logoBox.getBoundingClientRect().height
}px`;
main.style.height = `${
  window.innerHeight - logoBox.getBoundingClientRect().height
}px`;
tractorTable.style.height = `${
  0.3 * (Number.parseInt(main.style.height) - welHeight)
}px`;
mapComp.style.height = `${
  0.7 * (Number.parseInt(main.style.height) - welHeight)
}px`;
mapComp.style.gridTemplateRows = "30px auto";
console.log(mapComp.style);
thead.style.height = "26px";

tbody.style.height = `${
  Number.parseInt(tractorTable.style.height) -
  Number.parseInt(thead.style.height)
}px`;
tractorList.style.height = `${
  0.97 * Number.parseInt(mapComp.style.height) -
  mapheading.getBoundingClientRect().height
}px`;
mapFE.style.height = tractorList.style.height;

const reportWindowSize = function () {
  sidebar.style.width = `${logoBox.getBoundingClientRect().width}px`;
  sidebar.style.height = `${
    window.innerHeight - logoBox.getBoundingClientRect().height
  }px`;
  app.style.height = `${
    window.innerHeight - logoBox.getBoundingClientRect().height
  }px`;
  app.style.gridTemplateColumns = `${sidebar.style.width} auto`;
  main.style.height = `${
    window.innerHeight - logoBox.getBoundingClientRect().height
  }px`;
  tractorTable.style.height = `${
    0.3 * (Number.parseInt(main.style.height) - welHeight)
  }px`;
  mapComp.style.height = `${
    0.7 * (Number.parseInt(main.style.height) - welHeight)
  }px`;
  mapComp.style.gridTemplateRows = "30px auto";

  tbody.style.height = `${
    Number.parseInt(tractorTable.style.height) -
    Number.parseInt(thead.style.height)
  }px`;

  tractorList.style.height = `${
    0.97 * Number.parseInt(mapComp.style.height) -
    mapheading.getBoundingClientRect().height
  }px`;

  mapFE.style.height = tractorList.style.height;

  console.log(
    tractorTable.getBoundingClientRect().height,
    thead.style.height
    // tbody.style.height
  );
};

// document
//   .querySelector(".device-cont--1")
//   .addEventListener("click", function (e) {
//     document.querySelector(".mobile").classList.toggle("close-mobile");
//   });

// document
//   .querySelector(".mobile-icon-menu")
//   .addEventListener("click", function (e) {
//     e.preventDefault();
//     document.querySelector(".mobile").classList.toggle("close-mobile");
//   });

window.onresize = reportWindowSize;

// tractorList.addEventListener("click", activitateTractor);

tractorListType.addEventListener("change", function (e) {
  const index = e.target.value.split(" ")[1];
  // loadPopUp(latLng[index - 1], timeStamps[index - 1], markers[index - 1]);
});

const fetchApi2 = async function () {
  try {
    const res = await fetch("https://farmgoltd.com/iot/get_device_2");

    console.log(res);
    // if (!res.ok)
    //   throw new Error("Couldnot fetch https://farmgoltd.com/iot/get_device_2");
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const init = async function () {
  const data = await fetchApi2();
  data.forEach((data1, i) => {
    timeStamps.push(data1?.time);
    batteryLevel.push(data1?.battery_level);

    if (
      (data1?.lat < 90) &
      (data1?.lat > -90) &
      (data1?.lng < 90) &
      (data1?.lng > -90)
    ) {
      if (data[0] === data1) {
        const lat = Math.trunc(Math.random() * 60);
        const lng = Math.trunc(Math.random() * -60);
        loadmap(data?.[0]?.lat, data?.[0]?.lng);
        console.log(lat, lng);
        renderMarker(lat, lng);
        // loadPopUp([data1?.lat, data1?.lng], timeStamps[0], markers[0]);
      }

      latLng.push([data1?.lat, data1?.lng]);
      loadTractorList(i);
      renderMarker(data1?.lat, data1?.lng);
      renderTable(
        timeStamps[i],
        batteryLevel[i],
        latLng[i][0],
        latLng[i][1],
        i
      );
      tbody.style.height = `${
        Number.parseInt(tractorTable.style.height) -
        Number.parseInt(thead.style.height)
      }px`;
    }

    if (
      data1?.lat > 90 ||
      data1?.lat < -90 ||
      data1?.lng > 90 ||
      data1?.lng < -90
    ) {
      const lat = Math.trunc(Math.random() * 60);
      const lng = Math.trunc(Math.random() * -60);

      if (data[0] === data1) {
        loadmap(lat, lng);
        console.log(lat, lng);

        renderMarker(lat, lng);
        // loadPopUp([data1?.lat, data1?.lng], timeStamps[0], markers[0]);
      }
      latLng.push([lat, lng]);
      loadTractorList(i);
      renderMarker(lat, lng);
      renderTable(
        timeStamps[i],
        batteryLevel[i],
        latLng[i][0],
        latLng[i][1],
        i
      );
      tbody.style.height = `${
        Number.parseInt(tractorTable.style.height) -
        Number.parseInt(thead.style.height)
      }px`;
    }
  });
  // setMaxBound();
};
init();

if (module.hot) {
  module.hot.accept();
}
